import {Form, Link, redirect, useParams, useRouteLoaderData} from "react-router-dom";
import RelationshipFormFields from "../components/relationship-form-fields";
import formDataTransformer from "../utils/form-data-transformer";
import * as React from "react";
import RelationshipReadOnlyFormFields from "../components/relationship-read-only-form-fields";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId, fromMemberId, toMemberId, type } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${fromMemberId}/relationships/${toMemberId}/${type}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/members/${fromMemberId}/relationships/${toMemberId}/${type}`);
}

export default function RelationshipEdit() {
    const { relationship } = useRouteLoaderData('relationship');
    const { genogramId } = useParams();

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <Link to={'..'} relative={'path'} className={'ms-1 btn btn-outline-secondary'}>
                    <i className="bi bi-arrow-left"/>
                </Link>
                <button type={'submit'} className={'btn btn-success ms-auto'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Редактирование Связи</h2>
            <RelationshipReadOnlyFormFields relationship={relationship}/>
            <RelationshipFormFields relationship={relationship} readOnly={false}/>
        </Form>
    )
}