import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import {
    createBrowserRouter, redirect,
    RouterProvider,
} from "react-router-dom";

import Root, { action as rootAction} from './routes/root';
import Login from './routes/login';
import Genograms, {loader as genogramsLoader} from './routes/genograms';
import Genogram, {loader as genogramLoader, shouldRevalidate as genograShouldReinvalidate} from './routes/genogram';
import GenogramView from './routes/genogram-view';
import GenogramNew, {action as genogramCreateAction} from './routes/genogram-new';
import Member, {loader as memberLoader, shouldRevalidate as memberShouldReinvalidate} from "./routes/member";
import MemberView, {action as memberDeleteAction} from "./routes/member-view";
import MemberEdit, {action as memberEditAction} from "./routes/member-edit";
import MemberNew, {loader as memberCreateLoader, action as memberCreateAction} from "./routes/member-new";
import Relationship , {loader as relationshipLoader} from "./routes/relationship";
import RelationshipView , {action as relationshipDeleteAction} from "./routes/relationship-view";
import RelationshipEdit , {action as relationshipEditAction} from "./routes/relationship-edit";
import RelationshipNew , {loader as relationshipCreateLoader, action as relationshipCreateAction} from "./routes/relationship-new";
import Comments , {loader as commentsLoader} from "./routes/comments";
import CommentsAll , {action as commentsAllAction} from "./routes/comments-all";
import CommentNew , {action as commentsCreateAction} from "./routes/comment-new";
import CommentEdit , {action as commentEditAction} from "./routes/comment-edit";
import PaidServices , {loader as paidServicesLoader} from "./routes/paid-services";
import PaidServicesAll , {action as paidServicesAllAction} from "./routes/paid-services-all";
import PaidServiceNew , {action as paidServiceCreateAction} from "./routes/paid-service-new";
import PaidServiceEdit , {action as paidServiceEditAction} from "./routes/paid-service-edit";
import Export, {action as exportAction} from "./routes/export";
import MediaNew, {action as mediaNewAction} from "./routes/media-new";

import { UserManager } from "oidc-client-ts";
import {AuthProvider} from "react-oidc-context";

const auth = new UserManager({
    authority: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_nbDYbHlLM",
    client_id: "4inf9um6iubshr8b5e0b2l9js4",
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
    response_type: "code",
    scope: "email genograms/genogram.comments.read genograms/genogram.comments.write genograms/genogram.export.read genograms/genogram.paid-services.read genograms/genogram.paid-services.write genograms/genogram.read genograms/genogram.write genograms/genograms.read genograms/genograms.write openid phone profile",
});

const router = createBrowserRouter([
    {
        path: "/",
        id: 'root',
        element: <Root/>,
        action: rootAction,
        children: [
            {
                index: true,
                element: <Genograms/>,
                loader: genogramsLoader
            },
            {
                path: "genograms",
                element: <Genograms/>,
                loader: genogramsLoader
            },
            {
                path: "genograms/new",
                element: <GenogramNew/>,
                action: genogramCreateAction
            },
            {
                path: "genograms/:genogramId",
                element: <Genogram/>,
                loader: genogramLoader,
                shouldRevalidate: genograShouldReinvalidate,
                id: 'genogram',
                children: [
                    {
                        index: true,
                        element: <GenogramView/>,
                    },
                    {
                        path: "members/new",
                        element: <MemberNew/>,
                        action: memberCreateAction,
                        loader: memberCreateLoader,
                    },
                    {
                        path: "relationships/new",
                        element: <RelationshipNew/>,
                        action: relationshipCreateAction,
                        loader: relationshipCreateLoader
                    },
                    {
                        path: "comments",
                        element: <Comments/>,
                        loader: commentsLoader,
                        id: 'comments',
                        children: [
                            {
                                index: true,
                                element: <CommentsAll/>,
                                action: commentsAllAction
                            },
                            {
                                path: ":commentId/edit",
                                element: <CommentEdit/>,
                                action: commentEditAction
                            }
                        ]
                    },
                    {
                        path: "comments/new",
                        element: <CommentNew/>,
                        action: commentsCreateAction
                    },
                    {
                        path: "paid-services",
                        element: <PaidServices/>,
                        loader: paidServicesLoader,
                        id: 'paid-services',
                        children: [
                            {
                                index: true,
                                element: <PaidServicesAll/>,
                                action: paidServicesAllAction
                            },
                            {
                                path: ":paidServiceId/edit",
                                element: <PaidServiceEdit/>,
                                action: paidServiceEditAction
                            }
                        ]
                    },
                    {
                        path: "paid-services/new",
                        element: <PaidServiceNew/>,
                        action: paidServiceCreateAction
                    },
                    {
                        path: "export",
                        element: <Export/>,
                        action: exportAction
                    }
                ]
            },
            {
                path: "genograms/:genogramId/members/:memberId",
                element: <Member/>,
                loader: memberLoader,
                shouldRevalidate: memberShouldReinvalidate,
                id: 'member',
                children: [
                    {
                        index: true,
                        element: <MemberView/>,
                        action: memberDeleteAction
                    },
                    {
                        path: "edit",
                        element: <MemberEdit/>,
                        action: memberEditAction
                    },
                    {
                        path: "media/new",
                        element: <MediaNew/>,
                        action: mediaNewAction
                    }
                ]
            },
            {
                path: "genograms/:genogramId/members/:fromMemberId/relationships/:toMemberId/:type",
                element: <Relationship/>,
                id: 'relationship',
                loader: relationshipLoader,
                children: [
                    {
                        index: true,
                        element: <RelationshipView/>,
                        action: relationshipDeleteAction
                    },
                    {
                        path: "edit",
                        element: <RelationshipEdit/>,
                        action: relationshipEditAction
                    }
                ]
            },
            {
                path: "login",
                element: <Login/>
            }

        ]
    },
], {
    async dataStrategy({ request, matches }) {
        const matchesToLoad = matches.filter(
            (m) => m.shouldLoad
        );

        const currentUrl = new URL(request.url);
        const searchParams = currentUrl.searchParams;
        const redirectToLoginResult = { type: "data", result: redirect('/login') };

        let user = null;
        if ((searchParams.get("code") || searchParams.get("error")) && searchParams.get("state")) {
            user = await auth.signinCallback();
            window.history.replaceState(null, '', currentUrl.pathname);
        } else {
            user = await auth.getUser();
        }

        const isAuthenticated = user && !user.expired

        console.log('user: ', user);

        const results = await Promise.all(
            matchesToLoad.map(async (match) => {
                return isAuthenticated ? await match.resolve(handler => handler( { user })) : redirectToLoginResult;
            })
        );

        return results.reduce(
            (acc, result, i) =>
                Object.assign(acc, {
                    [matchesToLoad[i].route.id]: result,
                }),
            {}
        );
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider userManager={auth}>
        <RouterProvider router={router} />
      </AuthProvider>
  </React.StrictMode>
);