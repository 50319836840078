import {Outlet} from "react-router-dom";
import * as React from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const loader = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId } = params;
    const response = await fetch(`${resolveApiEndpoint()}/api/genograms/${genogramId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }
    const genogram = await response.json();
    return { genogram };
}

export function shouldRevalidate({ currentUrl, nextUrl }) {
    return currentUrl.pathname.split("/")[1] !== nextUrl.pathname.split("/")[1];
}

export default function Genogram() {
    return (
        <Outlet/>
    )
}