import {useAuth} from "react-oidc-context";

export default function Login() {
    const auth = useAuth();

    return (
        <main className={'container pb-5'}>
            {(auth.isLoading || auth.activeNavigator) &&
                <div className={"d-flex justify-content-center align-items-center vh-100"}>
                    <span className="spinner-border text-primary my-5"/>
                </div>
            }
            {!auth.isAuthenticated &&
                <div className={"d-flex justify-content-center align-items-center vh-100"}>
                    <button className="btn btn-primary" onClick={() => void auth.signinRedirect()}>Войти</button>
                </div>
            }
        </main>
    );
}