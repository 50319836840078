import {Form, Link, redirect, useParams, useRouteLoaderData} from "react-router-dom";
import formDataTransformer from "../utils/form-data-transformer";
import * as React from "react";
import PaidServiceFormFields from "../components/paid-service-fields";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId, paidServiceId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/paid-services/${paidServiceId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/paid-services`);
}

export default function CommentEdit() {
    const { genogram: { genogramId } } = useRouteLoaderData('genogram');
    const { paidServices } = useRouteLoaderData('paid-services');
    const { paidServiceId } = useParams();

    const paidService = paidServices.find(c => c.paidServiceId === paidServiceId) || {};

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} relative={'path'} className={'btn btn-outline-secondary me-1'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <Link to={`/genograms/${genogramId}/paid-services`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-arrow-left"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Редактирование Комментария</h2>
            <PaidServiceFormFields paidService={paidService} readOnly={false}/>
        </Form>
    )
}