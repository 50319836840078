import {Form, Link, useParams, redirect, useRouteLoaderData} from "react-router-dom";
import * as React from "react";
import formDataTransformer from "../utils/form-data-transformer";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";
import {useCallback, useEffect, useState} from "react";
import MemberKeyInfo from "../components/member-key-info";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId, memberId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));
    const { file: { type: mediaContentType }, version, media } = data;

    const mediaMetadataResponse = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/media`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify({
                memberId,
                genogramMediaType: mediaContentType
            })
        }
    );

    if (!mediaMetadataResponse.ok) {
        throw new Error(`Response status: ${mediaMetadataResponse.status}`);
    }

    const mediaMetadata = await mediaMetadataResponse.json();
    const { genogramMediaId, genogramMediaUrl, genogramMediaUploadUrl, genogramMediaUploadFormFields } = mediaMetadata;

    const uploadFormData = new FormData();

    uploadFormData.append('key', genogramMediaUploadFormFields.key);
    uploadFormData.append('Content-Type', mediaContentType);
    Object.entries(genogramMediaUploadFormFields)
        .filter(([name]) => name !== 'key')
        .forEach(([fieldName, fieldValue]) => uploadFormData.append(fieldName, fieldValue?.toString()));
    uploadFormData.append('file', data.file)

    const mediaUploadResponse = await fetch(
        genogramMediaUploadUrl, {
            method: 'POST',
            mode: "cors",
            body: uploadFormData
        }
    );

    if (!mediaUploadResponse.ok) {
        throw new Error(`Response status: ${mediaUploadResponse.status}`);
    }

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${memberId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify({
                media: [
                    {
                        genogramMediaId,
                        genogramMediaType: mediaContentType,
                        genogramMediaUrl,
                    },
                    ...JSON.parse(media)
                ],
                version
            })
        }
    )

    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/members/${memberId}`);
}

export default function MediaNew() {
    const { genogramId } = useParams();
    const { member } = useRouteLoaderData('member');
    const {version, media = []} = member;
    const [file, setFile] = useState(undefined);
    const [preview, setPreview] = useState(undefined)

    const onFileChange = useCallback((e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setFile(undefined);
        } else {
            setFile(e.target.files[0]);
        }
    }, [setFile])

    useEffect(() => {
        if (!file) {
            setPreview(undefined);
        } else {
            const objectUrl = URL.createObjectURL(file);
            setPreview(objectUrl)
            return () => URL.revokeObjectURL(objectUrl);
        }
}, [file, setPreview]);

    return (
        <Form method={'post'} className={'my-3'} encType="multipart/form-data">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Новый Файл Для:</h2>
            <input type={'hidden'} name={'version'} value={version}/>
            <input type={'hidden'} name={'media'} value={JSON.stringify(media)}/>
            <div className={'mb-3 d-flex justify-content-center'}>
                <MemberKeyInfo member={member}/>
            </div>
            <div className="mb-3">
                <label htmlFor="formFile" className="form-label">Файл:</label>
                <input className="form-control" type="file" id="formFile" name={'file'} accept={'image/*'} onChange={onFileChange}/>
            </div>
            {preview && <img className={'img-fluid rounded mx-auto d-block'} src={preview}/>}
        </Form>
    )
}