import {Form, Link, redirect, useParams, useRouteLoaderData} from "react-router-dom";
import MemberFormFields from "../components/member-form-fields";
import MemberRelationships from "../components/member-relationships";
import * as React from "react";
import formDataTransformer from "../utils/form-data-transformer";
import getRelationshipType from "../utils/get-relationship-type";
import MemberSearchField from "../components/member-search-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId, memberId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));
    const { version } = data;

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${memberId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify({ version })
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}`);
}


export default function MemberView() {
    const { member } = useRouteLoaderData('member');
    const { genogramId } = useParams();

    const parents = member.relationships?.filter(r => getRelationshipType(r) === 'parent') || [];
    const other = member.relationships?.filter(r => getRelationshipType(r) !== 'parent') || [];

    const onSubmit = (event) => {
        if (!window.confirm('Please confirm you want to delete this record.')) {
            event.preventDefault();
        }
    }

    return (
        <Form className={'my-3'} method="post" onSubmit={onSubmit}>
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} relative={'path'} className={'btn btn-outline-secondary'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <MemberSearchField genogramId={genogramId} inputProps={{className: 'form-control'}}
                                   className={'ms-1 flex-grow-1'}/>
                <Link to={'edit'} className={'btn btn-secondary mx-2'}>
                    <i className="bi bi-pen"/>
                </Link>
                <button className={'btn btn-danger'} type={'submit'}>
                    <i className="bi bi-trash"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Участник {member.firstName} {member.lastName}</h2>
            {parents.length > 0 && <MemberRelationships relationships={parents}/>}
            <MemberFormFields member={member} readOnly={true}/>
            {other.length > 0 && <MemberRelationships relationships={other}/>}
        </Form>
    )
}