import {Form, Link, redirect, useActionData, useLoaderData, useRouteLoaderData} from "react-router-dom";
import * as React from "react";
import formDataTransformer from "../utils/form-data-transformer";
import FormField from "../components/form-field";
import {useCallback, useEffect, useState} from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));
    const { language = 'ru', ...bodyData } = data;

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/export`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                //'Accept': 'application/json',
                'Accept-Language': language
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(bodyData)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    const blob = await response.blob();

    return { blob };
}

export default function Export() {
    const { genogram: { genogramId, rootMember } } = useRouteLoaderData('genogram');
    const { blob } = useActionData() || {}
    const [newWindowOpened, setNewWindowOpened] = useState(false);

    useEffect(() => {
        if(blob && !newWindowOpened) {
            const pdfWindow = window.open();
            const objectDataUrl = URL.createObjectURL(blob);

            if( pdfWindow ){
                pdfWindow.location.href = objectDataUrl;
            }

            setNewWindowOpened(true)

            return () => URL.revokeObjectURL(objectDataUrl);
        }
    }, [blob, newWindowOpened, setNewWindowOpened]);

    const onFormSubmit = useCallback(() => setNewWindowOpened(false), [setNewWindowOpened])

    return (
        <Form method={'post'} className={'my-3'} onSubmit={onFormSubmit}>
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
            </div>
            <h2 className={'my-2 text-center'}>Экспорт Генограммы {rootMember.firstName} {rootMember.lastName}</h2>
            <FormField name={'language'} type={'select'} label={'Язык'} options={{'ru': 'Русский'}} placeholder={'Не выбран'}/>
            <FormField name={'exportMediaType'} type={'select'} label={'Формат'} options={{'application/pdf': 'PDF'}} placeholder={'Не выбран'}/>
            <button type={'submit'} className={'btn btn-primary w-100 my-3'}>Экспорт</button>
        </Form>
    )
}