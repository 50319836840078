import {Outlet} from "react-router-dom";
import * as React from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const loader = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId, memberId } = params;
    const response = await fetch(`${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${memberId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }
    const member = await response.json();
    return {member};
}

export function shouldRevalidate({ currentUrl, nextUrl, actionResult }) {
    return currentUrl.pathname.split("/")[3] !== nextUrl.pathname.split("/")[3] || (actionResult && actionResult.errors === undefined);
}

export default function Member() {
    return (
        <Outlet/>
    )
}