import {Outlet} from "react-router-dom";
import * as React from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const loader = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId } = params;
    const response = await fetch(`${resolveApiEndpoint()}/api/genograms/${genogramId}/comments`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }
    const responseData = await response.json();
    return { comments: responseData.comments };
}
export default function Comments() {
    return (
        <Outlet/>
    )
}