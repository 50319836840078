import {Form, Link, redirect, useRouteLoaderData} from "react-router-dom";
import * as React from "react";
import CommentFormFields from "../components/comment-fields";
import toMembersOption from "../utils/to-member-options";
import formDataTransformer from "../utils/form-data-transformer";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export const action = async ({ request, params }, { user }) => {
    const token = user.access_token;
    const { genogramId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));
    const { version, commentId } = data;

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/comments/${commentId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify({ version })
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/comments`);
}

export default function CommentsAll() {
    const { genogram: { genogramId, rootMember, members } } = useRouteLoaderData('genogram');
    const { comments } = useRouteLoaderData('comments');

    const onSubmit = (event) => {
        if (!window.confirm('Please confirm you want to delete this record.')) {
            event.preventDefault();
        }
    }

    return (
        <div>
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
            </div>
            <h2 className={'my-2 text-center'}>Комментарии к Генограмме {rootMember.firstName} {rootMember.lastName}</h2>
            {comments.length === 0 && <div className={'py-1 my-2'}>Нет комментариев</div>}
            {comments.map(comment => (
                <Form method="post" onSubmit={onSubmit} key={comment.commentId} className={'py-2 my-2 d-flex'}>
                    <div className={'flex-grow-1'}>
                        <CommentFormFields comment={comment} readOnly={true} members={toMembersOption(members)}/>
                    </div>
                    <Link to={`${comment.commentId}/edit`} className="d-flex justify-content-center align-items-center ms-2">
                        <i className="bi bi-pencil text-secondary"/>
                    </Link>
                    <button type={"submit"} name={'commentId'} value={comment.commentId}
                            className="d-flex btn justify-content-center align-items-center ms-2">
                        <i className="bi bi-trash text-danger"/>
                    </button>
                </Form>
            ))}
        </div>
    )
}